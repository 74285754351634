import React from "react";

import formatInTimezone from "date-fns-tz/formatInTimeZone";
import { unsafeTimestampFormat } from "@qmspringboard/shared/src/utils/date";

export const login = {
  emailPlaceholder: "person@example.com",
  ucasIDPlaceholder: "0123456789",
  buttonText: "Sign in",
  buttonTextVerify: "I've checked, Sign me in anyway",
  sidebarTitle: "Signing In",
  sidebarBody: (
    <span>
      In order to make a Clearing application, please enter your UCAS personal ID number and email address used on your UCAS application form.
    </span>
  ),
};

export const home = {
  title: "Clearing Application Portal",
  body: (
    <div>
      <div>
        Welcome to the Queen Mary Clearing application portal. You can use this facility to make an online Clearing application for a course available
        in Clearing. You can only apply for one programme using the Clearing application portal. Please have your UCAS personal id number as well as
        scanned copies of your academic qualifications, and final grades to hand before starting your application.
      </div>
      <div>
        <strong>Applications received over the weekend will receive a decision on Monday.</strong>
      </div>
    </div>
  ),
  buttonText: "Create an Application",
};

export const offline = (until: Date) => {
  const format = unsafeTimestampFormat("HH:mm a 'on' iiii d LLLL");

  return {
    title: "Clearing Application Portal",
    body: (
      <span>
        Due to the UCAS results embargo we are not able to accept applications during this time. The online application form wil reopen at{" "}
        <span style={{ fontWeight: "bold" }}>{formatInTimezone(until, "Europe/London", format)}</span>
        .
        <br />
        <a href="https://qmul.tfaforms.net/292?utm_medium=ppc&utm_source=google&utm_campaign=HL_Clearing_2023&utm_content=home_brand">
          Register your interest in Clearing
        </a>
      </span>
    ),
  };
};

export const verify = {
  title: "Please check your email",
  body: <span>We sent a verification message to your address. Follow the instructions inside to continue your application. If you </span>,
  linkText: <span>didn&apos;t receive an email, check your email address and try again</span>,
};

// application

export const applicant = {
  gradesHeading: "Your Grades",
  qualificationsOtherYear: "Some or all of my qualifications were completed in a previous year",
  qualificationsHelpText: "Enter your grades below",
  additionalQualifications: "Additional Qualifications",
  additionalQualificationsDescription:
    "Some programmes may have additional requirements for English or Maths.  Check the qualifications you have below",
  qualificationsIELTS: "Type of English qualification",
  qualificationsMaths: "GCSE Maths grade B/5 or equivalent",
  placeholderForename: "Forenames",
  placeholderSurname: "Surname",
  placeholderTelephone: "+44 1234 567890",
  placeholderGDPR: (
    <span>
      I give permission for Queen Mary to store my information. For more information about how Queen Mary uses this information please see our{" "}
      <a href="http://www.arcs.qmul.ac.uk/governance/information-governance/data-protection/" rel="noopener noreferrer" target="_blank">
        data protection information page
      </a>
      .
    </span>
  ),
  placeholderConfirmedQualifications: "By clicking 'submit' you are confirming you have obtained the above qualifications.",
  applicantButtonText: "Submit",
  qualificationYearObtained: "Year Obtained",
  qualificationPlaceholderGrade: "Grade",
  qualificationUCATID: "UCAT ID",
  qualificationSubject: "Subject Name",
  qualificationIBYearObtained: "Year IB Obtained",
  qualificationIBTotalPoints: "Total IB Points",
  qualificationFillType: "What kind of qualifications do you have?",
  qualificationHasOtherAdvisory: (
    <span>
      Ensure you have completed any relevant English qualifications in <a href="#grade-entry">section 1 above</a>
    </span>
  ),
  qualificationTookGCSE: "What kind of secondary education qualifications did you take?",
  qualificationHasMinimumGCSE: "I have 5 or more GCSEs at grade C/4 or above (required for all programmes)",
  qualificationHasMinimumGCSEMedical: "I have 6 or more GCSEs with 3 at grade 6 and 3 at grade 7 (required for some medical programmes)",
  residency:
    "How long have you been ordinarily resident in the UK? (ordinarily resident – living a normal, continuous habitual, lawful residence in the UK)",
  immigration: "What is your current immigration status in the UK?",
  nationality: "What is your nationality?",
};

export type ApplicantStrings = typeof applicant;

export const applicationProgramme = {
  title: "What are you applying for?",
  body: "Choose the school and programme you wish to apply for",
  programmePlaceholder: "Choose a Programme",
  schoolPlaceholder: "Choose a School",
  doesNotMeetRequirementsForProgramme: (
    <>
      You cannot select this programme as you do not meet the requirements listed below. Please check the web pages for programme entry requirements,
      or call the <a href="https://www.qmul.ac.uk/undergraduate/clearing">Clearing Hotline</a> for advice
    </>
  ),
  showQualifiedProgrammes: "Show only programmes that I meet the requirements for",
  doesNotMeetRequirementsForAnyProgrammeAtSchool: "No programmes matched your requirements at this school",
  notQualifiedForProgrammesBelowHere: "-- You don't meet requirements for the programmes below --",
  undecidedProgrammesBelowHere: "-- An admissions advisor will check your grades for programmes below --",
  closedProgrammesBelowHere: "-- The programmes below are not accepting new applications --",
  referToAdvisor: "An admissions advisor will check your grades after you submit your application and get back to you",
};

export const applicationComplete = {
  title: "Thank You for your Application",
  applicationNumberPrefix: <span>Your unique applicant number is </span>,
  body: (
    <span>
      Thank you for submitting an application for a place at Queen Mary through Clearing. Please make note of the applicant number above for future
      reference. We hope to be able to welcome you to the university in September and will be in touch soon.
    </span>
  ),
  sidebarTitle: "Next Steps",
  sidebarBody: (
    <span>
      Your application will be carefully considered for a place on your chosen programme by Queen Mary. You will be informed of the final decision via
      email and we aim to make decisions on all Clearing applications within 3 working days. Please note that spaces are limited on all programmes and
      you must meet the advertised entry requirements in order to be considered for a place. If you have any questions about your application please
      call the Queen Mary Admissions office on <a href="tel:+44 (0)800 376 1800">+44 (0)800 376 1800</a>, quoting your applicant number.
    </span>
  ),
};

export const upload = {
  dropZoneText: (
    <span>
      Drag the requested documents here or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#">click to select from your computer</a>
      <br />
      You can send text, PDF, Word, Excel, JPG, and PNG files up to 20 MB in size.
    </span>
  ),
  complete: {
    title: "Documents Sent",
    body: (
      <span>
        Thank you for uploading the requested documents. Queen Mary will review the documents and be in touch if we require anything else from you.
      </span>
    ),
  },
  Grades: {
    title: "Add Grade Documents to Your Application",
    body: <span />,
  },
  IELTS: {
    title: "Add IELTS Documents to Your Application",
    body: <span />,
  },
  Other: {
    title: "Add Documents to Your Application",
    body: <span />,
  },
};

// errors
export const errors = {
  noMatch: "There was a problem matching your UCAS ID to your email address",
  network: "There was a problem talking to our servers",
  uploading: <span>There were problems uploading some of your files, you can retry or remove them</span>,
};

import { Dictionary, groupBy } from "lodash";
import React, { useEffect } from "react";
import { Button, Container, Grid, Header, Icon, List, Loader, Message, Popup } from "semantic-ui-react";
import styled from "styled-components";
import * as pot from "../api/pot";
import useModal from "../hooks/useModal";
import { AttachmentTypeEnum } from "../model/enums";
import { AttachmentRequest, FileId, FileInfo, AttachmentType } from "../model/types";
import { useAttachments } from "../reducers/applicantAttachments";
import { Opt } from "../utils";
import DateTime from "./DateTime";
import SidebarIconButton from "./SidebarIconButton";

const SuccessText = styled.span`
  color: green;
`;

const BasicButton = styled(SidebarIconButton)`
  float: right;
`;

const EditButtonsContainer = styled.div`
  position: absolute;
  background: -webkit-linear-gradient(left, rgba(243, 244, 245, 0) 0%, rgba(243, 244, 245, 1) 15%);
  padding-left: 20px;
  right: 0;
  top: 0;
  bottom: 0;
`;

const StatusTitle = styled.div`
  margin-top: 9px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #555;
`;

const EditButtons = styled(Button.Group)`
  margin-top: 6px !important;
`;

const FileItem = styled(List.Item)`
  position: relative;
  overflow-wrap: break-word;
`;

const AttachmentTypes = AttachmentTypeEnum.values;

type DoThing = (fileId: FileId) => void;

const fileDescription = (file: FileInfo) => {
  if (file.approvedBy != null) {
    return (
      <span>
        <SuccessText>
          Approved <DateTime iso={file.lastModifiedAt} />
        </SuccessText>
      </span>
    );
  } else {
    return (
      <span>
        Uploaded <DateTime iso={file.uploadedAt} />
      </span>
    );
  }
};

interface FileListProps {
  files: FileInfo[];
  onDelete: DoThing;
  onApprove: DoThing;
  onUnapprove: DoThing;
  onDownload: DoThing;
  editing: boolean;
}

const FileList = (props: FileListProps) => {
  const { files, onDelete, editing, onApprove, onUnapprove, onDownload } = props;

  return (
    <>
      {files.map(file => (
        <FileItem key={file.fileId}>
          <List.Header>
            <List.Header>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="javascript:void 0" onClick={() => onDownload(file.fileId)}>
                {file.name}
              </a>
            </List.Header>
          </List.Header>
          <List.Description>{fileDescription(file)}</List.Description>
          {editing && (
            <EditButtonsContainer>
              <EditButtons compact basic size="tiny">
                {file.approvedBy ? (
                  <Popup
                    content="Unapprove"
                    trigger={
                      <Button icon onClick={() => onUnapprove(file.fileId)}>
                        <Icon name="close" />
                      </Button>
                    }
                  />
                ) : (
                  <Popup
                    content="Approve"
                    trigger={
                      <Button icon onClick={() => onApprove(file.fileId)}>
                        <Icon name="check" />
                      </Button>
                    }
                  />
                )}
                <Popup
                  content="Delete"
                  trigger={
                    <Button icon onClick={() => onDelete(file.fileId)}>
                      <Icon name="trash alternate" />
                    </Button>
                  }
                />
              </EditButtons>
            </EditButtonsContainer>
          )}
        </FileItem>
      ))}
    </>
  );
};

interface Props {
  applicantId: number;
  applicantEmail: Opt<string>;
  username: string;
}

export default function ApplicantAttachments({ applicantId, applicantEmail, username }: Props) {
  const { attachmentPot, attachmentRequestsPot, fetchAttachments, attachmentDelete, attachmentApprove, attachmentRequest, attachmentDownload } =
    useAttachments();

  const confirmModal = useModal("confirm");
  const messageModal = useModal("message");
  const addFileModal = useModal("addFile");

  useEffect(() => {
    fetchAttachments(applicantId);
  }, [fetchAttachments, applicantId]);

  const handleAdd = (type: string) => {
    addFileModal.show({
      applicantId,
      type,
    });
  };

  const handleDelete = (fileId: FileId) => {
    confirmModal.show({
      onPrimaryClick: () => {
        attachmentDelete(fileId);
      },
      content: "Are you sure you want to delete this file?",
    });
  };

  const handleApproval = (newState: boolean) => (fileId: FileId) => {
    attachmentApprove(fileId, newState, username);
  };

  const handleRequest = (fileType: AttachmentType) => {
    messageModal.show({
      content: "Optionally add additional text to the attachment request message sent to the applicant",
      secondaryButtonText: "Cancel Request",
      primaryButtonText: "Send Request",
      title: "Add more information to the Request",
      onPrimaryClick: (message: string) => {
        attachmentRequest(fileType, applicantId, message);
      },
    });
  };

  const files = pot.getOrElse(attachmentPot, []);

  const errorFetching = pot.hasError(attachmentPot) || pot.hasError(attachmentRequestsPot);

  const isFetching = pot.fetching(attachmentPot) || pot.fetching(attachmentRequestsPot);

  const requestsByType: Dictionary<AttachmentRequest[]> = groupBy(
    pot.getOrElse(attachmentRequestsPot, []).filter(a => a.satisfiedAt === null),
    "attachmentType",
  );

  const hasNeedsApproval = files.findIndex(file => !file.approvedBy) >= 0;
  const [editing, setEditing] = React.useState(false);
  const approveAll = () => {
    files.filter(f => !f.approvedBy).map(({ fileId }) => handleApproval(true)(fileId));
  };

  return (
    <Container>
      <BasicButton onClick={() => setEditing(!editing)} icon={editing ? "close" : "write"} title={editing ? "Stop editing" : "Edit attachments"} />
      <Header as="h3" dividing>
        Attachments
      </Header>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {isFetching && <Loader>Getting Attachments</Loader>}

            {errorFetching && (
              <Message
                header="Couldn't fetch attachments"
                compact
                size="mini"
                content="There was a problem fetching attachments or requests, try refreshing to see them"
                negative
              />
            )}
            {AttachmentTypes.map(type => {
              const requests = requestsByType[type] || [];
              const hasRequests = requests.length > 0;
              const filesForType = files.filter(f => f.type === type);
              return (
                <React.Fragment key={type}>
                  <StatusTitle>{type}</StatusTitle>
                  <List>
                    {filesForType.length === 0 ? (
                      <div>No Attachments</div>
                    ) : (
                      <FileList
                        files={filesForType}
                        editing={editing}
                        onDelete={handleDelete}
                        onApprove={handleApproval(true)}
                        onUnapprove={handleApproval(false)}
                        onDownload={attachmentDownload}
                      />
                    )}
                  </List>
                  {hasRequests ? (
                    <span>
                      Files requested <DateTime iso={requests[requests.length - 1].requestedAt} />
                    </span>
                  ) : null}
                  <Container>
                    {editing &&
                      (applicantEmail ? (
                        <Button.Group size="tiny" basic fluid>
                          <Button
                            onClick={() => {
                              handleRequest(type);
                            }}
                          >
                            {hasRequests ? "Re-Request" : "Request"}
                          </Button>
                          <Button
                            onClick={() => {
                              handleAdd(type);
                            }}
                          >
                            Add
                          </Button>
                        </Button.Group>
                      ) : (
                        <Message negative>Cannot request attachments as applicant does not have an email set.</Message>
                      ))}
                  </Container>
                </React.Fragment>
              );
            })}
          </Grid.Column>
        </Grid.Row>
        {editing && (
          <Grid.Row>
            <Grid.Column>
              <Button icon="check" positive disabled={!hasNeedsApproval} basic fluid size="tiny" onClick={approveAll} content="Approve All" />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}

import React from "react";
import {
  EnglishQualification,
  GcseGrade,
  IeltsEnglishQualification,
  Qualifications,
  ToeflEnglishQualification,
  isGcseGrade,
} from "../model/qualifications.generated";
import { Message } from "../model/types";
import { ApplicantStrings } from "../strings";
import { Box, Field, Flex, ISelectOption, NullableNumberInput, NullableStringInput, Select } from "../ui";
import { mapMaybeYupValidationToMessages, qualificationEnglish } from "../validators";

type EnglishOptions = EnglishQualification["type"] | undefined;
const qualificationTypes: ISelectOption<EnglishOptions>[] = [
  { value: undefined, label: "Choose", disabled: true },
  { value: "Toefl", label: "TOEFL" },
  { value: "Gcse", label: "GCSE" },
  { value: "Ielts", label: "IELTS" },
  { value: "Other", label: "Not Listed" },
];

const width = ["100%", 120, 200, 200];

function GCSEQualifications({
  value,
  onChange,
  namespace,
  disabled,
}: {
  value?: GcseGrade | null;
  onChange: (newValue: GcseGrade | null) => void;
  namespace: string;
  disabled?: boolean;
}) {
  const handleChange = (newValue: string | undefined | null) => {
    if (newValue) {
      const upper = newValue.toUpperCase();
      if (isGcseGrade(upper)) {
        onChange(upper);
      }
    } else {
      onChange(null);
    }
  };
  return (
    <Field disabled={disabled} label="Grade" name={`${namespace}.grade`} mr={2} sx={{ width }}>
      {() => <NullableStringInput readOnly={disabled} value={value ?? null} onChange={handleChange} />}
    </Field>
  );
}

function TOEFLQualifications({
  namespace,
  value,
  onChange: parentChange,
  disabled,
}: {
  namespace: string;
  value: ToeflEnglishQualification;
  onChange: (newValue: ToeflEnglishQualification) => void;
  disabled?: boolean;
}) {
  const onChange =
    <K extends keyof ToeflEnglishQualification>(field: keyof ToeflEnglishQualification) =>
    (v: ToeflEnglishQualification[K] | undefined | null) => {
      parentChange({ ...value, [field]: v });
    };

  return (
    <Flex>
      <Field label="Overall" name={`${namespace}.overall`} disabled={disabled} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.overall} onChange={onChange("overall")} />}
      </Field>
      <Field label="Listening" name={`${namespace}.listening`} disabled={disabled} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.listening} onChange={onChange("listening")} />}
      </Field>
      <Field label="Reading" name={`${namespace}.reading`} disabled={disabled} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.reading} onChange={onChange("reading")} />}
      </Field>
      <Field label="Writing" name={`${namespace}.writing`} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.writing} onChange={onChange("writing")} />}
      </Field>
      <Field label="Speaking" name={`${namespace}.speaking`} disabled={disabled} sx={{ width }}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.speaking} onChange={onChange("speaking")} />}
      </Field>
    </Flex>
  );
}

function IELTSQualifications({
  value,
  onChange: parentChange,
  namespace,
  disabled,
}: {
  value: IeltsEnglishQualification;
  onChange: (newValue: IeltsEnglishQualification) => void;
  namespace: string;
  disabled?: boolean;
}) {
  const onChange =
    <K extends keyof IeltsEnglishQualification>(field: keyof IeltsEnglishQualification) =>
    (v: IeltsEnglishQualification[K] | undefined | null) => {
      parentChange({ ...value, [field]: v });
    };
  return (
    <Flex>
      <Field name={`${namespace}.overall`} disabled={disabled} label="Overall" sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} decimalPlaces={1} value={value.overall} onChange={onChange("overall")} />}
      </Field>
      <Field label="Listening" name={`${namespace}.listening`} disabled={disabled} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.listening} decimalPlaces={1} onChange={onChange("listening")} />}
      </Field>
      <Field label="Reading" name={`${namespace}.reading`} disabled={disabled} sx={{ width }} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.reading} decimalPlaces={1} onChange={onChange("reading")} />}
      </Field>
      <Field label="Writing" name={`${namespace}.writing`} sx={{ width }} disabled={disabled} mr={2}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.writing} decimalPlaces={1} onChange={onChange("writing")} />}
      </Field>
      <Field label="Speaking" name={`${namespace}.speaking`} disabled={disabled} sx={{ width }}>
        {({ htmlProps }) => <NullableNumberInput {...htmlProps} value={value.speaking} decimalPlaces={1} onChange={onChange("speaking")} />}
      </Field>
    </Flex>
  );
}

type Props = {
  value: Qualifications["english"];
  namespace: string;
  onChange: (newValue: Qualifications["english"]) => void;
  applicantStrings: ApplicantStrings;
  readOnly?: boolean;
};

export function QualificationsEnglish({ namespace, value, onChange, applicantStrings, readOnly }: Props): React.ReactElement {
  let content!: React.ReactElement;

  if (value) {
    switch (value.type) {
      case "Gcse": {
        content = (
          <GCSEQualifications
            namespace={namespace}
            value={value.grade}
            onChange={newGrade => onChange({ ...value, grade: newGrade })}
            disabled={readOnly}
          />
        );
        break;
      }
      case "Toefl": {
        content = (
          <TOEFLQualifications namespace={namespace} value={value} onChange={newValue => onChange({ ...value, ...newValue })} disabled={readOnly} />
        );
        break;
      }
      case "Ielts": {
        content = (
          <IELTSQualifications namespace={namespace} value={value} onChange={newValue => onChange({ ...value, ...newValue })} disabled={readOnly} />
        );
        break;
      }
      case "Other": {
        content = (
          <Box bg="lightGrey" mb={3} p={2}>
            {applicantStrings.qualificationHasOtherAdvisory}
          </Box>
        );
        break;
      }
    }
  }
  return (
    <>
      <Field label={applicantStrings.qualificationsIELTS} disabled={readOnly} name={`${namespace}.type`} sx={{ width: ["100%", "40%"] }}>
        {({ htmlProps }) => (
          <Select<EnglishOptions>
            {...htmlProps}
            value={value ? value.type : undefined}
            onChange={newValue => {
              if (newValue === undefined) {
                throw new Error("cannot be undefined");
              }
              if (!value || newValue !== value.type) {
                onChange({
                  type: newValue,
                });
              }
            }}
            options={qualificationTypes}
          />
        )}
      </Field>
      <Box mt={8}>{content}</Box>
    </>
  );
}

export const validate = (value: Qualifications["english"]): null | Message[] => {
  try {
    qualificationEnglish.parse(value);
  } catch (e) {
    return mapMaybeYupValidationToMessages(e);
  }
  return null;
};

import { assertNever } from "@qmspringboard/shared/dist/utils";
import { omit } from "lodash";
import type { Members, PresenceChannel } from "pusher-js";
import React from "react";
import { Container, Header, List, Message } from "semantic-ui-react";
import styled from "styled-components";
import { SchoolCode } from "@qmspringboard/shared/dist/model/core.generated";
import usePrevious from "../hooks/usePrevious";
import { usePusher } from "../hooks/usePusher";
import { schoolToTeamCode } from "../model/team";
import { diffArrays, joinEnglish } from "../utils/array";
import { InternalMessagesContext } from "./AnnouncementNotificationContainer";

interface Props {
  applicantId: number;
}

interface Member {
  username: string;
  school?: SchoolCode;
  email: string;
  name: string;
}

interface StateSuccess {
  status: "success";
  members: Record<string, Member>;
  me?: { id: string; info: Member };
}

type State =
  | {
      status: "idle";
    }
  | {
      status: "loading";
    }
  | {
      status: "error";
      error: unknown;
    }
  | StateSuccess;

type Actions =
  | {
      type: "RESET";
    }
  | {
      type: "SET_LOADING";
    }
  | {
      type: "SET_ERROR";
      payload: unknown;
    }
  | {
      type: "SET_SUCCESS";
      payload: State;
    }
  | {
      type: "ADD_MEMBER";
      payload: Member;
    }
  | {
      type: "REMOVE_MEMBER";
      payload: Member;
    };

const presenceChannelReducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "RESET": {
      return {
        status: "idle",
      };
    }
    case "SET_LOADING": {
      return { status: "loading" };
    }

    case "SET_ERROR": {
      return { status: "error", error: action.payload };
    }

    case "SET_SUCCESS": {
      return { ...state, ...action.payload };
    }

    case "ADD_MEMBER": {
      if (state.status !== "success") {
        // ignore
        console.info("ignored ADD_MEMBER as we weren't in success status");
        return state;
      }
      return {
        ...state,
        members: {
          ...state.members,
          [action.payload.username]: action.payload,
        },
      };
    }

    case "REMOVE_MEMBER": {
      if (state.status !== "success") {
        console.info("ignored REMOVE_MEMBER as we weren't in success status");
        // ignore
        return state;
      }
      return {
        ...state,
        members: omit(state.members, [action.payload.username]),
      };
    }
    default: {
      assertNever(action);
    }
  }
};

const useSubscribeToViewers = (applicantId: number) => {
  const result = usePusher();
  const [state, dispatch] = React.useReducer(presenceChannelReducer, {
    status: "idle",
  });

  const handleLoading = () => {
    dispatch({
      type: "SET_LOADING",
    });
  };

  const handleError = (err: unknown) => {
    dispatch({
      type: "SET_ERROR",
      payload: err,
    });
  };

  const handleSuccess = (members: Members) => {
    dispatch({
      type: "SET_SUCCESS",
      payload: {
        status: "success",
        me: members.me,
        members: members.members,
      },
    });
  };

  const handleAdd = (member: { info: Member }) => {
    dispatch({
      type: "ADD_MEMBER",
      payload: member.info,
    });
  };

  const handleRemove = (member: { info: Member }) => {
    dispatch({
      type: "REMOVE_MEMBER",
      payload: member.info,
    });
  };

  React.useEffect(() => {
    switch (result.status) {
      case "success": {
        const {
          pusher,
          config: {
            applicantPresence: { channelPrefix },
          },
        } = result;
        const channelName = `${channelPrefix}${applicantId}`;

        try {
          pusher.connection.bind("connecting", handleLoading);
          pusher.connection.bind("error", handleError);

          const channel = pusher.subscribe(channelName) as PresenceChannel;

          channel.bind("pusher:subscription_error", handleError);
          channel.bind("pusher:subscription_succeeded", handleSuccess);
          channel.bind("pusher:member_added", handleAdd);
          channel.bind("pusher:member_removed", handleRemove);

          return () => {
            channel.unbind_all();
            pusher.unsubscribe(channelName);
            pusher.connection.unbind_all();
          };
        } catch (e) {
          handleError(e);
        }
        return;
      }
      case "loading": {
        return handleLoading();
      }
      case "idle": {
        return dispatch({ type: "RESET" });
      }
      case "error": {
        return handleError(new Error("couldn't init pusher"));
      }
    }
    return;
  }, [applicantId, result]);

  return state;
};

function membersExcludingSelf(state: State): Member[] {
  switch (state.status) {
    case "success":
      return Object.values(state.me ? omit(state.members, [state.me.id]) : state.members);

    default:
      return [];
  }
}

const EllipsisListHeader = styled(List.Header)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal !important;
`;

const EllipsisListDescription = styled(List.Description)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0.25em 0;
  font-size: 0.9rem;
`;

function OtherViewers({ applicantId }: Props): React.ReactElement | null {
  const { addMessage } = React.useContext(InternalMessagesContext);
  const [messageShown, setMessageShown] = React.useState(false);
  const result = useSubscribeToViewers(applicantId);

  const currMembers = membersExcludingSelf(result);
  const prevMembers = usePrevious(currMembers);
  const [added, _removed] = diffArrays(prevMembers, currMembers, (x, y) => x.username === y.username);

  React.useEffect(() => {
    if (added.length > 0) {
      const id = new Date().getTime();

      const detail = [
        joinEnglish(added.map(member => `${member.name} (${schoolToTeamCode(member.school)})`)),
        added.length === 1 ? "is" : "are",
        "viewing this applicant",
      ].join(" ");

      addMessage({
        id,
        acknowledged: false,
        authorName: "",
        headline: "Other people are viewing this applicant",
        detail,
        timestamp: new Date().toISOString(),
      });
      setMessageShown(true);
    }
  }, [addMessage, added, messageShown, result]);

  switch (result.status) {
    case "loading":
    case "idle": {
      return null;
    }
    case "error": {
      return (
        <Message>
          <p>We could not get a list of other people viewing this</p>
        </Message>
      );
    }
    case "success": {
      const members = membersExcludingSelf(result);
      if (members.length === 0) {
        return <p>No other people are viewing this applicant</p>;
      }
      return (
        <List>
          {members.map(member => (
            <List.Item key={member.username}>
              <EllipsisListHeader>
                {member.name} ({schoolToTeamCode(member.school)})
              </EllipsisListHeader>
              <EllipsisListDescription>
                <a href={`mailto:${member.email}`}>{member.email}</a>
              </EllipsisListDescription>
            </List.Item>
          ))}
        </List>
      );
    }
    default: {
      assertNever(result);
    }
  }
}

export function ApplicantOtherViewers({ applicantId }: Props): React.ReactElement {
  return (
    <Container>
      <Header as="h3" dividing>
        Other Viewers
      </Header>
      <OtherViewers applicantId={applicantId} />
    </Container>
  );
}

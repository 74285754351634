import { FileStatus, groupByStatus, isValid } from "@qmspringboard/shared/dist/reducers/uploads";
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { connectModal } from "redux-modal";
import { Button, Grid, Header, List, Modal, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { AttachmentType } from "../model/enums";
import { useAttachments } from "../reducers/applicantAttachments";
import { ConnectModalProps } from "../utils/modal";

const Container = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 15px;
  min-height: 150px;
`;

const DropArea = styled.div`
  border: 2px dashed grey;
  cursor: pointer;
  align-items: center;
  padding: 15px 0;
`;

interface FileItemProps {
  name: string;
  status: FileStatus;
  retry: (e: SyntheticEvent) => void;
  remove: (e: SyntheticEvent) => void;
}

const FileItem = ({ name, status, retry, remove }: FileItemProps) => {
  let statusString = null;
  let loading = false;
  switch (status) {
    case FileStatus.NEW: {
      statusString = "Ready to Upload";
      break;
    }
    case FileStatus.PENDING: {
      statusString = "Waiting to Upload";
      break;
    }

    case FileStatus.UPLOADING: {
      statusString = "Uploading";
      loading = true;
      break;
    }

    case FileStatus.COMPLETE: {
      statusString = "Uploaded";
      break;
    }

    case FileStatus.ERROR: {
      statusString = "Failed";
      break;
    }

    default:
      statusString = "Unknown";
  }
  return (
    <List.Item>
      <List.Icon loading={loading} name={loading ? "spinner" : "file"} size="large" verticalAlign="middle" />
      <List.Content>
        <List.Header>{name}</List.Header>
        <List.Description>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>{statusString}</Grid.Column>
              <Grid.Column width={8}>
                <Button.Group compact basic size="tiny">
                  {(status === FileStatus.ERROR || status === FileStatus.NEW) && <Button onClick={remove}>Remove</Button>}
                  {status === FileStatus.ERROR && <Button onClick={retry}>Retry</Button>}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

interface Props extends ConnectModalProps {
  applicantId: number;
  accept?: Accept;
  maxSize?: number;
  type: AttachmentType;
}

function AddFile(props: Props) {
  const {
    applicantId,
    show,
    handleHide,
    accept = {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/vnd.ms-excel": [".xls", ".xlsx"],
      "application/vnd.ms-word": [".doc", ".docx"],
    },
    maxSize = 20 * 1024 * 1024,
    type,
  } = props;

  const [uploading, setUploading] = useState(false);

  const {
    fetchAttachments,
    attachmentUploadData,
    setNewUploadsToPending,
    resetUploads,
    setUploadNew,
    setUploadPending,
    removeUpload,
    uploadAttachments,
  } = useAttachments();

  const statuses = useMemo(() => {
    return groupByStatus(attachmentUploadData);
  }, [attachmentUploadData]);

  const handleUpload = () => {
    setUploading(true);
    setNewUploadsToPending();
  };

  const hasFiles = Object.keys(attachmentUploadData).length > 0;

  const attachmentValid = isValid(attachmentUploadData);

  const onClose = useCallback(() => {
    resetUploads();
    handleHide();
  }, [handleHide, resetUploads]);

  useEffect(() => {
    if (uploading && attachmentValid && hasFiles) {
      // shutdown and refetch attachments
      const action = async () => {
        setUploading(false);
        await fetchAttachments(applicantId);
        onClose();
      };

      action();
    }
  }, [applicantId, attachmentValid, fetchAttachments, handleHide, hasFiles, onClose, resetUploads, uploading]);

  const upload = useCallback((files: File[]) => uploadAttachments(applicantId, files, type), [applicantId, uploadAttachments, type]);

  if (statuses.PENDING.length > 0 && statuses.UPLOADING.length < 5) {
    const toUpload = statuses.PENDING.slice(0, 5);
    upload(toUpload);
  }

  const [wrongType, setWrongType] = useState<Array<string>>([]);
  const [tooLarge, setTooLarge] = useState<Array<string>>([]);

  const handleDrop = useCallback(
    async (files: File[]) => {
      setWrongType([]);
      setTooLarge([]);
      uploading ? setUploadPending(files) : setUploadNew(files);
    },
    [setUploadNew, setUploadPending, uploading],
  );

  function handleRejections(fileRejections: Array<FileRejection>) {
    const wrong = fileRejections.filter(rejection =>
      // The error can be due to file with valid type being too large, but be reported with a code of "file-invalid-type". So we
      // check the text of the error message as well to deal with this. Very annoying.
      rejection.errors.some(error => (error.code = "file-invalid-type") && !error.message.startsWith("File is larger than")),
    );
    setWrongType(wrong.map(rejection => rejection.file.name));

    // Find the items that aren't rejected for being of the wrong type, so we can check their
    // sizes. This makes sure we only display one error for any given file.
    const remaining = fileRejections.filter(rejection => !wrong.includes(rejection));

    const large = remaining.filter(rejection =>
      rejection.errors.some(error => (error.code = "file-too-large" || error.message.startsWith("File is larger than"))),
    );
    setTooLarge(large.map(rejection => rejection.file.name));
  }

  const remove = (file: File) => (e: SyntheticEvent) => {
    e.stopPropagation();
    removeUpload(file);
  };

  const retry = (file: File) => (e: SyntheticEvent) => {
    e.stopPropagation();
    handleDrop([file]);
  };

  const cancelDisabled = uploading && statuses.ERROR.length === 0;
  const uploadDisabled = uploading || !hasFiles;

  const acceptedMimeTypes: Array<string> = Object.keys(accept);
  const acceptedExtensions: Array<string> = Object.values(accept).flat();
  const accepted = acceptedMimeTypes.concat(acceptedExtensions).join(",");

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Header content={`Add ${type} Files to Applicant`} />
      <Segment textAlign="center" basic>
        <List horizontal relaxed>
          <List.Item>
            <List.Header>Maximum file size</List.Header>
            20 MB
          </List.Item>
          <List.Item>
            <List.Header>Supported files</List.Header>
            PDF, text, JPEG, PNG, Word, and Excel
          </List.Item>
        </List>
      </Segment>
      <Modal.Content>
        <Dropzone onDrop={handleDrop} maxSize={maxSize} accept={accept} onDropRejected={fileRejections => handleRejections(fileRejections)}>
          {({ getRootProps, getInputProps }) => (
            <DropArea>
              <Container {...getRootProps()}>
                <input accept={accepted} {...getInputProps()} />
                <div>
                  Drag the documents here or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#">click to select from your computer</a>
                </div>
                <List>
                  {Object.keys(attachmentUploadData).map(fname => {
                    return (
                      <FileItem
                        key={fname}
                        remove={remove(attachmentUploadData[fname].file)}
                        retry={retry(attachmentUploadData[fname].file)}
                        name={fname}
                        {...attachmentUploadData[fname]}
                      />
                    );
                  })}
                </List>
              </Container>
            </DropArea>
          )}
        </Dropzone>
        {wrongType.length > 0 && (
          <Segment>
            <span style={{ color: "red" }}>The following files cannot be uploaded as they are of the wrong type:</span>
            <ul>
              {wrongType.map((fileName, index) => {
                return (
                  <li style={{ color: "red" }} key={index}>
                    {fileName}
                  </li>
                );
              })}
            </ul>
          </Segment>
        )}
        {tooLarge.length > 0 && (
          <Segment>
            <span style={{ color: "red" }}>The following files cannot be uploaded as they are too large:</span>
            <ul>
              {tooLarge.map((fileName, index) => {
                return (
                  <li style={{ color: "red" }} key={index}>
                    {fileName}
                  </li>
                );
              })}
            </ul>
          </Segment>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={cancelDisabled} onClick={onClose}>
          Cancel
        </Button>
        <Button primary disabled={uploadDisabled} onClick={handleUpload}>
          Upload
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default connectModal({ name: "addFile" })(AddFile);

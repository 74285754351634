import { API_ROOT, createHeaders, usePusherConfig, useFetchParams } from "../api";
import Pusher from "pusher-js/with-encryption";
import pick from "lodash/pick";
import { PusherConfigDTO } from "../model/announcements.generated";
import React from "react";

type HookReturn =
  | {
      status: "success";
      pusher: Pusher;
      config: PusherConfigDTO;
    }
  | {
      status: "error" | "idle" | "loading";
      pusher: null;
      config: null;
    };

const empty = {
  pusher: null,
  config: null,
};

export function usePusher(): HookReturn {
  const fetchParams = useFetchParams();
  const config = usePusherConfig();
  return React.useMemo(() => {
    switch (config.status) {
      case "error":
      case "idle":
      case "loading": {
        return { ...empty, status: config.status };
      }

      case "success": {
        const { key, cluster } = config.data;
        if (!key) {
          console.info("no key defined for pusher, not subscribing");
          // don't even try...
          return { ...empty, status: "error" };
        }
        try {
          const headers = pick(createHeaders(fetchParams), ["Authorization", "X-Acting-As"]);
          const pusher = new Pusher(key, {
            cluster,
            authEndpoint: API_ROOT + "/push/auth",
            auth: {
              headers,
            },
          });

          return {
            status: config.status,
            pusher,
            config: config.data,
          };
        } catch (e) {
          return {
            ...empty,
            status: "error",
          };
        }
      }
    }
  }, [config.data, config.status, fetchParams]);
}

import React, { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { DateInput, DateInputProps } from "semantic-ui-calendar-react-17";
import { Form, Grid, Header, List, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { ApplicantDetails, DuplicateApplicants } from "../model/applicant";
import { subErrors } from "../model/errors";
import { formatNameWithInitials } from "../model/name";
import { ApplicantDTO } from "../model/types";
import ErrorDisplay from "./ErrorDisplay";
import { BaseFieldProps, FieldLabel, NullableTextInput } from "./fields";
import UcasLink from "./UcasLink";

interface Props extends BaseFieldProps<ApplicantDetails> {
  duplicates: DuplicateApplicants;
}

const initialDate = `${new Date().getFullYear() - 18}-01-01`;

const PaddedHeader = styled(Header)`
  padding-top: 5px !important;
`;

const PlainLink = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #4183c4;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover {
    color: #1e70bf;
    text-decoration: underline;
  }
`;

const MutedPara = styled.p`
  opacity: 0.8;
`;

const DuplicatesView = ({ items }: { items: ApplicantDTO[] }) =>
  items.length > 0 ? (
    <Segment secondary style={{ border: "none", boxShadow: "none" }}>
      <p>We found other similar applicants:</p>
      <List>
        {items.map(appt => (
          <List.Item key={appt.id}>
            <List.Icon name="exclamation" />
            <List.Content>
              <Link to={`/applicant/${appt.id}`} target="_blank">
                UCAS Personal ID: {appt.details.ucasPersonalId}
                <br />
                Student ID: {appt.details.studentId}
                <br />
                Name: {formatNameWithInitials(appt.details.surname, appt.details.forenames)}
              </Link>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Segment>
  ) : (
    <span />
  );

export default function ApplicantDetailsEditor(props: Props) {
  const { messages, onChange, value, readOnly, duplicates } = props;

  const splitErrors = subErrors(messages, {
    ucasPersonalId: true,
    studentId: true,
    email: true,
    surname: true,
    forenames: true,
    telephone1: true,
    telephone2: true,
    dateOfBirth: true,
    countryOfDomicile: true,
    ukResidency: true,
    ukImmigrationStatus: true,
    nationality: true,
    feeStatus: true,
  });

  const handleDateChange = (_e: SyntheticEvent, field: DateInputProps) => {
    const dateOfBirth = field.value;
    onChange?.({ ...value, dateOfBirth });
  };

  const handleCopySitsEmailAsMainEmail = () => {
    onChange?.({ ...value, email: value.sitsHomeEmail });
  };

  const ucasLabel = (
    <span>
      <span style={{ float: "right" }}>
        <UcasLink value={value && value.ucasPersonalId} />
      </span>
      UCAS Personal ID
    </span>
  );

  return (
    <Form as="div">
      <PaddedHeader>Applicant Details</PaddedHeader>

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label={ucasLabel}>
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.ucasPersonalId}
                value={value.ucasPersonalId}
                onChange={ucasPersonalId => onChange?.({ ...value, ucasPersonalId })}
                maxLength={10}
                fluid
              />
            </FieldLabel>
            <DuplicatesView items={duplicates.ucasPersonalId} />
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Email">
              <NullableTextInput
                readOnly={readOnly}
                type="email"
                messages={splitErrors.email}
                value={value.email}
                onChange={email => onChange?.({ ...value, email })}
                fluid
              />
            </FieldLabel>
            {!readOnly && value.sitsHomeEmail != null && value.sitsHomeEmail !== value.email && (
              <MutedPara>
                The applicant&apos;s email on SITS is <PlainLink href={`mailto:${value.sitsHomeEmail}`}>{value.sitsHomeEmail}</PlainLink>
                {". "}
                {!readOnly && value.email == null && <LinkButton onClick={handleCopySitsEmailAsMainEmail}>Use this</LinkButton>}
              </MutedPara>
            )}
            <DuplicatesView items={duplicates.email} />
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Forenames">
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.forenames}
                value={value.forenames}
                onChange={forenames => onChange?.({ ...value, forenames })}
                fluid
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Surname">
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.surname}
                value={value.surname}
                onChange={surname => onChange?.({ ...value, surname })}
                fluid
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Telephone 1">
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.telephone1}
                value={value.telephone1}
                onChange={telephone1 => onChange?.({ ...value, telephone1 })}
                fluid
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Telephone 2">
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.telephone2}
                value={value.telephone2}
                onChange={telephone2 => onChange?.({ ...value, telephone2 })}
                fluid
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Date of birth">
              {/* The DateInput component accepts focus when readOnly is true.
                  Wrapping in a semantic-ui field set improves this.
                  The border styling around the input is still darker than other fields on the page.
               */}
              <fieldset disabled={readOnly} style={{ border: 0, padding: 0 }}>
                <DateInput
                  name="dob"
                  initialDate={initialDate}
                  readOnly={readOnly}
                  dateFormat="YYYY-MM-DD"
                  startMode="year"
                  placeholder="Date of birth"
                  closable
                  value={value.dateOfBirth || ""}
                  iconPosition="left"
                  onChange={handleDateChange}
                  fluid
                />
                <ErrorDisplay messages={splitErrors.dateOfBirth} attached></ErrorDisplay>
              </fieldset>
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label={"Student ID"}>
              <NullableTextInput
                readOnly={readOnly}
                messages={splitErrors.studentId}
                value={value.studentId}
                onChange={studentId => onChange?.({ ...value, studentId })}
                maxLength={10}
                fluid
              />
            </FieldLabel>
            <DuplicatesView items={duplicates.studentId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ErrorDisplay messages={splitErrors._rest_} />
    </Form>
  );
}

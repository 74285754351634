import React from "react";
import styles from "./AnnouncementNotificationContainer.module.css";
import update from "immutability-helper";
import Portal from "./Portal";
import Notification from "./Notification";
import { useAnnouncements, useAcknowledgeAnnouncement, addAnnouncementToCache } from "../api";
import { AnnouncementDTO } from "../model/announcements.generated";
import { useLocation } from "react-router";
import { usePusher } from "../hooks/usePusher";

const useSubscribeToNewAnnouncements = () => {
  const [error, setError] = React.useState<unknown | null>(null);
  const [status, setStatus] = React.useState<"success" | "loading" | "error" | "idle">("idle");
  const result = usePusher();

  React.useEffect(() => {
    switch (result.status) {
      case "success": {
        const {
          pusher,
          config: {
            announcement: { eventName, channelName },
          },
        } = result;
        setStatus("loading");

        try {
          pusher.connection.bind("connecting", function () {
            setStatus("loading");
            setError(null);
          });

          pusher.connection.bind("error", function (err: Error) {
            setStatus("error");
            setError(err);
          });

          const channel = pusher.subscribe(channelName);

          channel.bind("pusher:subscription_error", function (err: Error) {
            setStatus("error");
            setError(err);
          });

          channel.bind("pusher:subscription_succeeded", function () {
            setStatus("success");
            setError(null);
          });

          // trust the pusher event is the same shape as the api
          channel.bind(eventName, function (data: AnnouncementDTO) {
            // inject the pusher resource into the announcements cache
            addAnnouncementToCache(data);
          });

          return () => {
            channel.unbind_all();
            pusher.connection.unbind_all();
          };
        } catch (e) {
          setStatus("error");
          setError(e);
        }
        return;
      }
      default: {
        setStatus(result.status);
      }
    }
    return;
  }, [result]);

  return [status, error];
};

type InternalMessagesContextType = {
  addMessage: (message: AnnouncementDTO) => void;
  clear: () => void;
  messages: AnnouncementDTO[];
};

export const InternalMessagesContext = React.createContext<InternalMessagesContextType>({
  clear: () => {
    throw new Error("replaceme");
  },
  addMessage: (_message: AnnouncementDTO) => {
    throw new Error("replaceme");
  },
  messages: [],
});

export function InternalMessagesContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [messages, setMessages] = React.useState<AnnouncementDTO[]>([]);
  const addMessage = React.useCallback(
    (message: AnnouncementDTO) => {
      const fi = messages.findIndex(m => m.id === message.id);
      if (fi > -1) {
        // replace existing id if we have it
        setMessages(messages => [...messages, message]);
      } else {
        setMessages(update(messages, { $splice: [[fi, 1, message]] }));
      }
    },
    [messages],
  );

  const clear = React.useCallback(() => {
    setMessages([]);
  }, []);

  const value = React.useMemo(
    () => ({
      addMessage,
      clear,
      messages,
    }),
    [addMessage, clear, messages],
  );
  return <InternalMessagesContext.Provider value={value}>{children}</InternalMessagesContext.Provider>;
}

function AnnouncementNotificationContainer() {
  const location = useLocation();
  // FIXME: maybe show a notification if we error?
  const [subscribeStatus, subscribeError] = useSubscribeToNewAnnouncements();
  const announcements = useAnnouncements(true);
  const { mutate: handleAcknowledge } = useAcknowledgeAnnouncement();
  const internalMessages = React.useContext(InternalMessagesContext);

  if (subscribeStatus === "error") {
    if (subscribeError) {
      console.error(subscribeError);
    }
  }

  if (location.pathname === "/announcements") {
    return null;
  }

  switch (announcements.status) {
    case "error": {
      // FIXME: maybe show a notification
      console.error(announcements.error);
      return null;
    }
    case "idle":
    case "loading": {
      return null;
    }
    case "success": {
      // only bother creating a portal if we have announcements
      if (announcements.data.total === 0 && internalMessages.messages.length === 0) {
        return null;
      }

      return (
        <Portal className={styles.portal} id="announcements">
          <div className={styles.notificationContainer}>
            <Notification
              maxToPreview={3}
              messages={announcements.data.items}
              onAcknowledge={() => {
                const maxSeenId = announcements.data.items.reduce((acc, a) => (a.id > acc ? a.id : acc), 0);
                handleAcknowledge(maxSeenId);
              }}
            />
            <Notification
              maxToPreview={3}
              messages={internalMessages.messages}
              onAcknowledge={() => {
                internalMessages.clear();
              }}
            />
          </div>
        </Portal>
      );
    }
  }
}

export default React.memo(AnnouncementNotificationContainer);
